function App() {
	return (
		<div className='App'>
			<header className='App-header'>
				<a className='App-link' href='https://shyro.health'>
					shyro.health
				</a>
			</header>
		</div>
	);
}

export default App;
